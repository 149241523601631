<template>
  <modal
      @close="$emit('close')"
      class="custom-popup large-size"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AreYouSure'])"></div>
        {{ $t('heartysan_chooseTypeTitle.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_AreYouSure'])"></div>
            {{ $t('heartysan_chooseTypeSubTitle.localization_value.value') }}
          </div>
        </div>
        <div class="custom-row">
          <div class="custom-col">
            <div class="choose-express-order__list custom-row justify-content-center">
              <div class="choose-express-order__item custom-col custom-col--50 mb-0"
                   v-for="(item, index) in heartysanUserTypes"
                   :key="index"
              >
                <OrderTypeCardBig class="btn-style" :text="item.currentTranslate.name" @click.native="selectTypeQuestion(item.id)"></OrderTypeCardBig>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></span>
          <router-link :to="$store.getters.GET_PATHS.dashboard">
            <MainButton
                class="wfc"
                :value="$t('common_goToDashboard.localization_value.value')"
            />
          </router-link>
        </div>

      </div>
    </template>
    <template slot="footer">

    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import {HEARTYSAN_USER_TYPES} from "@/staticData/staticVariables";
import OrderTypeCardBig from "@/components/coreComponents/OrderTypeCardBig/OrderTypeCardBig";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";

export default {
  name: "HeartyChooseTypePopup",
  components: {
    MainButton,
    OrderTypeCardBig,
    Modal,
  },

  watch: {
    loadUserAuthorizedData() {
      this.initTypeUser()
    },
  },

  data() {
    return {
      heartysanUserTypes: []
    }
  },

  mounted() {
    if(this.loadUserAuthorizedData){
      this.initTypeUser()
    }
  },

  methods: {

    initTypeUser() {

      console.log(22);
      let typeId = this.getCurrentUser?.hearty_user_type?.id
      if(typeId){
        switch (typeId) {
          case HEARTYSAN_USER_TYPES.HEARTYSAN_MANUFACTURER.id :
            this.$router.push(this.$store.getters.GET_PATHS.heartysanManufacturerListings)
            break
          case HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id :
            this.$router.push(this.$store.getters.GET_PATHS.heartysanSellerListings)
            break
        }
      }

      this.$store.dispatch('getHeartysanTypes').then(response => {
        this.heartysanUserTypes = this.getRespPaginateData(response)
      })
    },

    selectTypeQuestion(id) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.selectType(id)

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    selectType(id) {
      let data = {
        user_id: this.getCurrentUser?.id,
        type_id: id
      }
      this.$store.dispatch('setHeartysanTypes', data).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            location.reload()
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      });
    },
  },

}

</script>

<style scoped>
.choose-express-order__item >>> .order-type-card{
  height: 290px;
}
.custom-popup >>> .btn-close{
  display: none;
}

.choose-express-order__item{
  color: #8f7a61;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
}

</style>
