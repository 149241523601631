<template>
<!--  <div style="max-width: 800px; margin: 100px auto;">-->
<!--    -->
<!--  </div>-->
  <HeartyChooseTypePopup
      v-if="loadUserRoleAndAuthorizedData"
  />

</template>

<script>
// import OrderTypeCardBig from "@/components/coreComponents/OrderTypeCardBig/OrderTypeCardBig";

import HeartyChooseTypePopup
  from "@/components/modules/HeartysanModule/popups/HeartyChooseTypePopup/HeartyChooseTypePopup";
export default {
  name: "HeartysanChoice",

  components: {HeartyChooseTypePopup},

}
</script>

<style scoped>

</style>